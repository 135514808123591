import React, { useState, useEffect } from 'react';
import './Co2_card.css';
import co2_img from '../assets/co2_img.png';

function Co2_card({ data }) {
  // Utilisez les données passées en props
  const latestData = data.length > 0 ? data[0] : {}; // Prend la première donnée de la liste
  const [isBlinking, setIsBlinking] = useState(false);

  useEffect(() => {
    // Vérifiez si la valeur de CO2 dépasse 2000 et activez le clignotement si nécessaire
    if (latestData.co2 && latestData.co2 > 2000) {
      setIsBlinking(true);
    } else {
      setIsBlinking(false);
    }
  }, [latestData.co2]);

  return (
    <div className="Co2_card_container">
      <div className='co2_img_container'>
        <img src={co2_img} alt="co2_img" className='co2_img' />
      </div>
      <h3 className='CO2_title'>CO2</h3>
      <div className='CO2_value'>{latestData.co2 || '0'}</div> {/* Utilisez la dernière valeur de CO2 */}
      <div className='ppm'>ppm</div>
      <div className={`voyant ${isBlinking ? 'blink' : ''}`}></div>
    </div>
  );
}

export default Co2_card;
