import React from 'react';
import './Inscription_blocA.css';
import axios from 'axios';
import logo from '../assets/image2.png';
import { Link } from 'react-router-dom';

function Inscription_blocA(){
    return(
        <div className = "Inscription_blocA_container">
            <div className = "logo_container">
                <img src = {logo} className = "logo2" alt = "logo2"/>
            </div>

            <div className = "login_container">
                <h2 className= "bvn">Bienvenue !</h2>
                <p className = "p_login">
                Pour accéder à AIRQUALITY MONITOR, 
                veuillez vous connecter avec vos informations
                 personnelles si vous avez dejà un compte
                </p>
                <div className = "button_container">
                    <Link to = "/connexion" className='button_link'><div className='login_button'>Se connecter</div></Link>
                </div>
            </div>

        </div>
    );
}

export default Inscription_blocA;