import React, { useState, useEffect } from 'react';
import './Hum_card.css';
import hum_img from '../assets/hum_img.png';

function Hum_card({ data }) {
  // Utilisez les données passées en props
  const latestData = data.length > 0 ? data[0] : {}; // Prend la première donnée de la liste
  const [isBlinking, setIsBlinking] = useState(false);

  useEffect(() => {
    // Vérifiez si la valeur d'humidité dépasse 70% et activez le clignotement si nécessaire
    if (latestData.humidite && latestData.humidite > 60) {
      setIsBlinking(true);
    } else {
      setIsBlinking(false);
    }
  }, [latestData.humidite]);

  return (
    <div className="Hum_card_container">
      <div className='hum_img_container'>
        <img src={hum_img} alt="hum_img" className='hum_img' />
      </div>
      <h3 className='hum_title'>Humidité</h3>
      <div className='hum_value'>{latestData.humidite || '0'}</div> {/* Utilisez la dernière valeur d'humidité */}
      <div className='hum'>%</div>
      <div className={`voyant2 ${isBlinking ? 'blink' : ''}`}></div>
    </div>
  );
}

export default Hum_card;
