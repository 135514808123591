import React, { useState, useEffect } from 'react';
import './COV_card.css';
import cov_img from '../assets/cov_img.png';

function COV_card({ data }) {
  // Utilisez les données passées en props
  const latestData = data.length > 0 ? data[0] : {}; // Prend la première donnée de la liste
  const [isBlinking, setIsBlinking] = useState(false);

  useEffect(() => {
    // Vérifiez si la valeur de COV dépasse 2000 et activez le clignotement si nécessaire
    if (latestData.voc && latestData.voc > 800) {
      setIsBlinking(true);
    } else {
      setIsBlinking(false);
    }
  }, [latestData.voc]);

  return (
    <div className="COV_card_container">
      <div className='cov_img_container'>
        <img src={cov_img} alt="cov_img" className='cov_img' />
      </div>
      <h3 className='COV_title'>COV</h3>
      <div className='COV_value'>{latestData.voc || '0'}</div> {/* Utilisez la dernière valeur de COV */}
      <div className='ppb'>ppb</div>
      <div className={`voyant1 ${isBlinking ? 'blink' : ''}`}></div>
    </div>
  );
}

export default COV_card;
