import React, { useState, useEffect, useContext } from 'react';
import { PieChart, Pie, Cell, Tooltip } from 'recharts';
import './Hum_chart.css';
import { DataContext } from './DataContext';

function Hum_chart() {
  const [containerWidth, setContainerWidth] = useState(0);
  const [containerHeight, setContainerHeight] = useState(0);
  const data = useContext(DataContext); // Utilisez le contexte pour obtenir les données

  useEffect(() => {
    // Récupérer la largeur et la hauteur du conteneur
    const container = document.querySelector('.Hum_chart_container');
    setContainerWidth(container.offsetWidth);
    setContainerHeight(container.offsetHeight);

    // Écouter les événements de redimensionnement de la fenêtre
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleResize = () => {
    const container = document.querySelector('.Hum_chart_container');
    setContainerWidth(container.offsetWidth);
    setContainerHeight(container.offsetHeight);
  };

  const humidityData = data.length > 0 ? data[0].humidite : 0; // Utilisez la première valeur d'humidité

  const chartSize = Math.min(containerWidth, containerHeight) * 0.8;
  const innerRadius = chartSize * 0.2;
  const outerRadius = chartSize * 0.4;

  const chartData = [
    { name: 'Humidité', value: humidityData },
    { name: 'Humidité', value: 100 - humidityData },
  ];

  const COLORS = ['rgb(135, 206, 255, 1)', '#808080'];

  return (
    <div className="Hum_chart_container">
      <h1 className="Hum_title">Niveau d'Humidité</h1>
      <PieChart width={chartSize} height={chartSize} className="Hum_pie">
        <Pie
          dataKey="value"
          data={chartData}
          cx="50%"
          cy="50%"
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          fill="#8884d8"
        >
          {chartData.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Tooltip contentStyle={{ fontSize: '12px' }} />
        <text
          x="50%"
          y="50%"
          textAnchor="middle"
          dominantBaseline="middle"
          fontSize={chartSize * 0.1}
          fontWeight="bold"
          fill="#000"
        >
          {`${humidityData.toFixed(0)}%`}
        </text>
      </PieChart>
    </div>
  );
}

export default Hum_chart;
