import React, { useState, useEffect, useContext } from 'react';
import { PieChart, Pie, Cell } from 'recharts';
import './Temp_chart.css';
import { DataContext } from './DataContext';

const RADIAN = Math.PI / 180;
const data = [
  { name: '0-20°C', value: 20, color: '#87CEEB', description: "Température normale pour l'homme" },
  { name: '20-38°C', value: 15, color: '#FFA500', description: 'Température élevée, mais encore supportable' },
  { name: '38-100°C', value: 65, color: '#FF6347', description: "Température critique, dangereuse pour l'homme" },
];

const needle = (value, data, cx, cy, iR, oR, color) => {
  let total = 0;
  data.forEach((v) => {
    total += v.value;
  });
  const ang = 180.0 * (1 - value / total);
  const length = (iR + 2 * oR) / 3;
  const sin = Math.sin(-RADIAN * ang);
  const cos = Math.cos(-RADIAN * ang);
  const r = 5;
  const x0 = cx + 5;
  const y0 = cy + 5;
  const xba = x0 + r * sin;
  const yba = y0 - r * cos;
  const xbb = x0 - r * sin;
  const ybb = y0 + r * cos;
  const xp = x0 + length * cos;
  const yp = y0 + length * sin;
  return [
    <circle cx={x0} cy={y0} r={r} fill={color} stroke="none" />,
    <path d={`M${xba} ${yba}L${xbb} ${ybb} L${xp} ${yp} L${xba} ${yba}`} stroke="#none" fill={color} />,
  ];
};

function Temp_chart() {
  const [containerWidth, setContainerWidth] = useState(0);
  const [containerHeight, setContainerHeight] = useState(0);
  const dataContext = useContext(DataContext); // Utilisation du contexte pour accéder aux données
  const latestTemperature = dataContext.length > 0 ? dataContext[0].temperature : null; // Récupération de la température la plus récente

  useEffect(() => {
    // Récupérer la largeur et la hauteur du conteneur
    const container = document.querySelector('.Temp_chart_container');
    setContainerWidth(container.offsetWidth);
    setContainerHeight(container.offsetHeight);

    // Écouter les événements de redimensionnement de la fenêtre
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleResize = () => {
    const container = document.querySelector('.Temp_chart_container');
    setContainerWidth(container.offsetWidth);
    setContainerHeight(container.offsetHeight);
  };

  const chartSize = Math.min(containerWidth, containerHeight) * 0.8;
  const cx = chartSize / 2;
  const cy = chartSize / 2;
  const innerRadius = chartSize * 0.2;
  const outerRadius = chartSize * 0.4;

  return (
    <div className="Temp_chart_container">
      <h2 className='temp_title'>État de la Température</h2>
      <div className="chart-wrapper">
        <PieChart width={chartSize} height={chartSize} className='Temp_pie'>
          <Pie
            dataKey="value"
            startAngle={180}
            endAngle={0}
            data={data}
            cx={cx}
            cy={cy}
            innerRadius={innerRadius}
            outerRadius={outerRadius}
            fill="#8884d8"
            stroke="none"
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.color} />
            ))}
          </Pie>
          {needle(latestTemperature, data, cx, cy, innerRadius, outerRadius, '#d0d000')} {/* Utilisation de la dernière température */}
        </PieChart>
        <div className="label-container">
          <span className="label">{latestTemperature}°C</span> {/* Affichage de la dernière température */}
        </div>
      </div>
      <div className="legend">
        {data.map((entry, index) => (
          <div key={index} className="legend-item">
            <span className="legend-color" style={{ backgroundColor: entry.color }}></span>
            <span className="legend-text">{entry.description}</span>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Temp_chart;