import React from 'react';
import axios from 'axios';
import './Dashboard.css';
import Navbarre from '../components/Navbarre';
import Dashboard_component from '../components/Dashboard_component';

function Dashboard(){

    return(
        <div className = "Dashboard_container">
            <Navbarre />
            <Dashboard_component />
        </div>
    );
}

export default Dashboard;