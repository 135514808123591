import React from 'react';
import './PageInscription.css';
import axios from 'axios';
import Inscription_blocA from '../components/Inscription_blocA';
import Inscription_blocB from '../components/Inscription_blocB';


function PageInscription(){
    return(
        <div className = "PageInscription_container">
            <Inscription_blocA />
            <Inscription_blocB />
        </div>
    );
}

export default PageInscription;