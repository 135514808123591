import React from 'react';
import axios from 'axios';
import './PageMotDePasseOublie.css';
// import Forgot_component from '../components/Forgot_component';


function PageConnexion(){
    return(
        <div className = "PageMotDePasseOublie_container">
           
        </div>
    );
}

export default PageConnexion;