import React, { useState, useEffect, useContext } from 'react';
import './Dashboard_component.css';
import Co2_card from '../components/Co2_card';
import COV_card from '../components/COV_card';
import Hum_card from '../components/Hum_card';
import Temp_card from '../components/Temp_card';
import Alert_card from '../components/Alert_card';
import User_card from '../components/User_card';
import CO2_chart from '../components/CO2_chart';
import Temp_chart from '../components/Temp_chart';
import COV_chart from '../components/COV_chart';
import Hum_chart from '../components/Hum_chart';
import filter_icon1 from '../assets/filter_icon.png';
import { DataContext } from './DataContext';

function Dashboard_component() {
  const [selectedOption, setSelectedOption] = useState('lastHour');
  const [currentDate, setCurrentDate] = useState(new Date());
  const data = useContext(DataContext);
  const [filteredData, setFilteredData] = useState([]);

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentDate(new Date());
    }, 1000);

    // Récupérer les données filtrées de l'API
    const fetchData = async () => {
      try {
        const response = await fetch('https://s-p5.com/tiene/airqualityProject/data_filter1.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ request: selectedOption }),
        });
        const data = await response.json();
        setFilteredData(data);
      } catch (error) {
        console.error('Erreur lors de la récupération des données :', error);
      }
    };

    fetchData();

    return () => clearInterval(timer);
  }, [selectedOption]);

  const formattedDate = `${currentDate.getDate()} / ${currentDate.getMonth() + 1} / ${currentDate.getFullYear()}`;

  return (
    <div className="Dashboard_component">
      <div className='dash_blocA'>
        <div className='card_element'>
          <div className='TB_container'>
            <h2 className='TB'>Tableau de bord</h2>
            <div className='date'><strong>{formattedDate}</strong></div>
          </div>
          <div className='filter_container_d'>
            <div htmlFor="option_select_d" className='option_select_d'>
              <img src={filter_icon1} alt='icone du filtre' className='filter_icon_d' />
            </div>
            <select id="option_select_d" className='option_select1_d' value={selectedOption} onChange={handleOptionChange}>
              <option value="lastHour">Dernière heure</option>
              <option value="lastDay">Dernier jour</option>
              <option value="lastWeek">Dernière semaine</option>
            </select>
          </div>
          <Alert_card data={data} />
          <div className='param1_container'>
            <Co2_card data={data} />
            <COV_card data={data} />
            <Hum_card data={data} />
            <Temp_card data={data} />
          </div>
        </div>
        <User_card />
      </div>
      <div className='dash_blocB'>
        <Temp_chart data={data} />
        <CO2_chart data={filteredData} />
      </div>
      <div className='dash_blocC'>
        <Hum_chart data={data} />
        <COV_chart data={filteredData} />
      </div>
    </div>
  );
}

export default Dashboard_component;
