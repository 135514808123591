import React, { useState, useEffect } from 'react';
import './Alert_card.css';
import alert_img from '../assets/alert_img.png';

function Alert_card({ data }) {
  const [alertMessage, setAlertMessage] = useState('');
  const [suggestionMessage, setSuggestionMessage] = useState('');
  const [showAlert, setShowAlert] = useState(false);

  const latestData = data && data.length > 0 ? data[0] : {};

  useEffect(() => {
    let alertTimer;

    const showAlertMessage = (message) => {
      setAlertMessage(message);
      setSuggestionMessage(''); // Réinitialise le message de suggestion
      setShowAlert(true);

      alertTimer = setTimeout(() => {
        setShowAlert(false);
      }, 5000); // Masquer le message après 5 secondes
    };

    const showSuggestionMessage = (message) => {
      setSuggestionMessage(message);
      setAlertMessage(''); // Réinitialise le message d'alerte
      setShowAlert(true);

      alertTimer = setTimeout(() => {
        setShowAlert(false);
      }, 5000); // Masquer le message après 5 secondes
    };

    if (!latestData || typeof latestData.co2 !== 'number') {
      // Aucune donnée valide
      return;
    }

    const { co2, voc, humidite, temperature } = latestData;

    if (co2 >= 2000) {
      showAlertMessage('Seuil de CO2 atteint. Veuillez aérer la pièce ou utiliser un dispositif de ventilation.');
    } else if (voc >= 1000) {
      showAlertMessage("Seuil des COV atteint. Assurez-vous de ventiler la zone ou utilisez des purificateurs d'air.");
    } else if (humidite >= 60) {
      showAlertMessage("Seuil d'humidité atteint. vérifiez si les sources d'humidité, telles que les fuites ou les infiltrations, sont correctement traitées.");
    } else if (temperature >= 38) {
      showAlertMessage('Température élevée. Vérifiez la climatisation, ou ouvrez les fenêtres pour une ventilation.');
    } else {
      // Aucune condition vérifiée, affiche la suggestion par défaut en continu
      showSuggestionMessage('Tout est normal pour le moment. Aucune anomalie détectée.');
    }

    return () => {
      clearTimeout(alertTimer);
    };
  }, [latestData]);

  return (
    <div className="alert_card_container">
      <div className='alert_img_container'>
        <img src={alert_img} alt="alert_img" className='alert_img' />
      </div>
      <div className='barre'></div>
      <div className='alert_message'>{showAlert && (alertMessage || suggestionMessage)}</div>
    </div>
  );
}

export default Alert_card;
