import React, { useState, useEffect } from 'react';
import { useTable } from 'react-table';
import Papa from 'papaparse';
import './Data_table.css'; 
import filter_icon from '../assets/filter_icon.png';

// URL de l'API
const API_URL = 'https://s-p5.com/tiene/airqualityProject/data_filter1.php';

function Data_table() {
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [selectedOption, setSelectedOption] = useState('lastHour');
  const [currentDate, setCurrentDate] = useState(new Date());

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentDate(new Date());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(API_URL, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ request: selectedOption }),
        });
        const jsonData = await response.json();

        // Mappage des noms de colonnes
        const columnMappings = {
          co2: 'CO2',
          voc: 'COV',
          humidite: 'Humidité',
          temperature: 'Température'
        };

        // Exclure les colonnes "id" et "uv" et modifier les noms des colonnes
        const tableColumns = Object.keys(jsonData[0])
          .filter(key => key !== 'id' && key !== 'uv')
          .map(key => ({
            Header: columnMappings[key] || key, // Utilisez le nom modifié s'il est défini dans columnMappings, sinon utilisez le nom original
            accessor: key,
          }));
        setColumns(tableColumns);

        setData(jsonData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [selectedOption]);

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleCSVExport = () => {
    // Filtrer les données pour inclure uniquement les colonnes CO2, COV, Humidité, Température et date de réception
    const filteredData = data.map(row => ({
        CO2: row.co2,
        COV: row.voc,
        Humidité: row.humidite,
        Température: row.temperature,
        date_reception: row.date_reception
    }));

    // Convertir les données filtrées en CSV
    const csvData = Papa.unparse(filteredData);

    // Créer un objet Blob à partir des données CSV
    const csvBlob = new Blob([csvData], { type: 'text/csv;charset=utf-8' });

    // Créer une URL pour le Blob
    const csvUrl = URL.createObjectURL(csvBlob);

    // Créer un lien d'ancrage pour le téléchargement du fichier CSV
    const link = document.createElement('a');
    link.setAttribute('href', csvUrl);
    link.setAttribute('download', 'data.csv');

    // Simuler un clic sur le lien d'ancrage pour démarrer le téléchargement
    link.click();
};

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ columns, data });

  const formattedDate = `${currentDate.getDate()} / ${currentDate.getMonth() + 1} / ${currentDate.getFullYear()}`;

  return (
    <div className="Data_table">
      <div className='hd_container'>
        <h2 className='Hd'>Tableau de bord</h2>
        <div className='date1'><strong>{formattedDate}</strong></div>
      </div>
      <div className='filter_container'>
        <div htmlFor="option_select" className='option_select'><img src={filter_icon} alt='icone du filtre' className='filter_icon'/></div>
        <select id="option_select" className='option_select1' value={selectedOption} onChange={handleOptionChange}>
          <option value="lastHour">Dernière heure</option>
          <option value="lastDay">Dernier jour</option>
          <option value="lastWeek">Dernière semaine</option>
        </select>
        <button onClick={handleCSVExport} className='csv_button'>Exporter en CSV</button>
      </div>
      <div className="table_wrapper">
        <table {...getTableProps()} className="data_table">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, index) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} style={{ backgroundColor: index % 2 === 0 ? '#8ECCFA' : 'transparent' }}>
                  {row.cells.map((cell) => (
                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Data_table;
