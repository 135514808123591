import './Navbarre.css';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import nav_logo from '../assets/image2.png';
import databot from '../assets/databot.png';
import dashboard_icon from '../assets/dashboard_icon.png';
import history_icon from '../assets/history_icon.png';
import logOut_icon from '../assets/logOut_icon.png';

function Navbarre() {
    const [isOpen, setIsOpen] = useState(window.innerWidth > 1023);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 1023);
    const [activeLink, setActiveLink] = useState('');
    const [showToggleBtn, setShowToggleBtn] = useState(window.innerWidth <= 667);

    useEffect(() => {
      const handleResize = () => {
        setIsOpen(window.innerWidth > 1023);
        setIsMobile(window.innerWidth <= 1023);
        setShowToggleBtn(window.innerWidth <= 1023);
      };
    
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);

    const toggleNavWindow = () => {
        if (isMobile) {
            setIsOpen(!isOpen);
        } else {
            setIsOpen(prevIsOpen => !prevIsOpen);
        }
    };

    const handleLinkClick = (link) => {
        setActiveLink(link);
    };

  return (
    <div className={`navigation_window ${isOpen ? 'open' : 'closed'}`}>
      <div className="nav_logo_container">
        <img src={nav_logo} className="nav_logo" alt="nav_logo" />
      </div>
      <div className="nav_header">
        {showToggleBtn && (<button className={`toggle_btn ${isOpen ? 'open' : ''}`} onClick={toggleNavWindow} ></button>)}
      </div>
      <nav className= 'nav_menu'>
        <ul className="Link_container">
          <li className={`tab ${activeLink === 'dashboard' ? 'active' : ''}`} onClick={() => handleLinkClick('dashboard')}>
            <img src={dashboard_icon} className="dashboard_icon" alt="dashboard_icon" />
            <Link to="/dashboard" className="nav_Link">
              Tableau de bord
            </Link>
          </li>
          <li className={`hd ${activeLink === 'history' ? 'active' : ''}`} onClick={() => handleLinkClick('history')}>
            <img src={history_icon} className="history_icon" alt="history_icon" />
            <Link to="/historics" className="nav_Link">
              Historiques des données
            </Link>
          </li>
          <li className={`dc ${activeLink === 'logout' ? 'active' : ''}`} onClick={() => handleLinkClick('logout')}>
            <img src={logOut_icon} className="logOut_icon" alt="logOut_icon" />
            <Link to="/connexion" className="nav_Link">
              Déconnexion
            </Link>
          </li>
        </ul>
      </nav>
      <div className="databot_container">
        <img src={databot} className="databot" alt="databot" />
      </div>
    </div>
  );
}

export default Navbarre;