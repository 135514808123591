import './PageHistoriques.css';
import Navbarre from '../components/Navbarre';
import Data_table from '../components/Data_table';

function PageHistoriques() {

  return (
    <div className='PageHistoriques_container'>
      <Navbarre />
      <Data_table />
    </div>
  );
}

export default PageHistoriques;