import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Modal from 'react-modal';
import './User_card.css';
import settings_img from '../assets/settings_img.png';
import logOut_user from '../assets/logOut_user.png';
import user_icon from '../assets/user_icon.png';
import Cookies from 'js-cookie'; // Importer js-cookie

Modal.setAppElement('#root'); // Nécessaire pour la bibliothèque react-modal

function User_card() {
  const [selectedLanguage, setSelectedLanguage] = useState('fr');
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [userInfo, setUserInfo] = useState(null); // Ajouter un état pour stocker les informations de l'utilisateur

  useEffect(() => {
    // Charger les informations de l'utilisateur à partir du cookie lors du montage du composant
    const userInfoFromCookie = Cookies.get('user_info');
    if (userInfoFromCookie) {
      setUserInfo(JSON.parse(userInfoFromCookie));
    }
  }, []); // Utiliser une dépendance vide pour s'assurer que cela ne s'exécute qu'une seule fois lors du montage du composant

  const handleLanguageChange = (e) => {
    setSelectedLanguage(e.target.value);
    // Ici, vous pouvez effectuer d'autres actions en fonction de la langue sélectionnée
  };

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <div className="user_card_container">
      <div className='user_card_header'>
        {/* <select className="language_dropdown" value={selectedLanguage} onChange={handleLanguageChange}>
          <option value="fr">Fr</option>
          <option value="en">En</option>
          <option value="es">Es</option>
        </select> */}
        <div className='tools'>
          <div className='settings_img_container' onClick={openModal}>
            <img src={settings_img} alt="settings_img" className='settings_img' />
          </div>
          <Link to="/connexion"><div className='logOut_user_container'><img src={logOut_user} alt="logOut_user" className='logOut_user' /></div></Link>
        </div>
      </div>
      <div className='user_icon_container'><img src={user_icon} alt="user_icon" className='user_icon' /></div>
      {userInfo && (
        <div className='wel_user_container'>Bienvenue<strong className='name_strong'>{userInfo.username}</strong></div>
      )}

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="User Information"
        className="user_modal"
        overlayClassName="user-modal-overlay"
      >
        <h2 className='user_inf'>Informations de l'utilisateur</h2>
        {userInfo && (
          <>
            <p className='user_em'>Email: {userInfo.email}</p>
            <p className='user_nm'>Nom: {userInfo.username}</p>
          </>
        )}
        <button className='user_cb' onClick={closeModal}>Fermer</button>
      </Modal>
    </div>
  );
}

export default User_card;
