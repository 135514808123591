import React, { useState, useEffect } from 'react';
import './Connexion_BlocB.css';
import { Link } from 'react-router-dom';

function Connexion_BlocB() {
  const [salutation, setSalutation] = useState('');

  useEffect(() => {
    const currentHour = new Date().getHours();
    let greetingMessage = '';

    if (currentHour >= 5 && currentHour < 12) {
      greetingMessage = 'Bonjour';
    } else if (currentHour >= 12 && currentHour < 18) {
      greetingMessage = 'Bon après-midi';
    } else {
      greetingMessage = 'Bonsoir';
    }

    setSalutation(greetingMessage);
  }, []);

  return (
    <div className="Connexion_BlocB_container">
      <h2 className="title_salutation">{salutation} !</h2>
      <p className="p_SignUp">
        Entrez vos informations personnelles pour vous s'inscrire
      </p>
      <div className="signUpbutton_container">
        <Link to="/" className="signUpbutton_link">
          <div className="signinbutton_button">S'inscrire</div>
        </Link>
      </div>
    </div>
  );
}

export default Connexion_BlocB;