import './Inscription_blocB.css';
import React, { useState } from 'react';
import axios from 'axios';

function Inscription_blocB() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = () => {
    if(name.length === 0){
      alert("Veuillez inserer votre nom dans le champs Nom");
    }
    else if(email.length === 0){
      alert("Veuillez inserer votre email dans le champs E-mail");
    }
    else if(password.length === 0){
      alert("Veuillez inserer votre mot de passe dans le champs Mot de passe");
    }
    else{
      const url = "https://s-p5.com/tiene/airqualityProject/traitement_inscprition.php";

      let fData = new FormData();
      fData.append('name', name);
      fData.append('email', email);
      fData.append('password', password);

      axios.post(url, fData)
      .then(response => {setMessage(response.data);
        setName(''); // Réinitialiser le champ nom
        setEmail(''); // Réinitialiser le champ email
        setPassword(''); // Réinitialiser le champ mot de passe
      })
      .catch(error => setMessage(error));
    }
  }

  let messageStyle;

  if (message === "Inscription réussie.") {
    messageStyle = "success-message";
  } 
  else {
    messageStyle = "error-message";
  }

  return(
    <div className = "Inscription_blocB_container">
      <h2 className="title_inscription">Créer un compte</h2>
      <div className="form_container">
        <div className="name_container">
          <label className="name_label">Nom :</label>
          <input type = "text" name = "name" className = "name_field" style={{ fontSize: '1em', paddingLeft: '15px' }} value = {name} onChange = {(e) => setName(e.target.value)} autoComplete="off"placeholder="Veuillez insérer votre nom ici"/>
        </div>

        <div className="email_container">
          <label className="email_label">E-mail :</label>
          <input type = "text" name = "email" className = "email_field" style={{ fontSize: '1em', paddingLeft: '15px' }} value = {email} onChange = {(e) => setEmail(e.target.value)} placeholder="Veuillez insérer votre email ici"/>
        </div>

        <div className="password_container">
          <label className="password_label">Mot de passe :</label>
          <input type = "password" name = "password" className = "password_field" value = {password} style={{ fontSize: '1em', paddingLeft: '15px' }} onChange = {(e) => setPassword(e.target.value)} autoComplete="off" placeholder="Veuillez insérer votre mot de passe ici"/>
        </div>

        <div className="messages_container">
          <div className={`messages ${messageStyle}`}>{message}</div>
        </div>

        <div className="button_form_container">
          <input type = "button" name = "send" className = "signUp_button" value = "S'inscrire" onClick = {handleSubmit}/>
        </div>
      </div>
    </div>
  );
}

export default Inscription_blocB;