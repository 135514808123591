import React from 'react';
import axios from 'axios';
import './PageConnexion.css';
import Connexion_BlocA from '../components/Connexion_BlocA';
import Connexion_BlocB from '../components/Connexion_BlocB';

function PageConnexion(){
    return(
        <div className = "PageConnexion_container">
            <Connexion_BlocA />
            <Connexion_BlocB />
        </div>
    );
}

export default PageConnexion;