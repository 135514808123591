import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Connexion_BlocA.css';
import axios from 'axios';
import logo1 from '../assets/image1.png';
import User_card from '../components/User_card';
import Cookies from 'js-cookie';

function Connexion_BlocA(){
  const [email1, setEmail1] = useState('');
  const [password1, setPassword1] = useState('');
  const [message1, setMessage1] = useState('');
  const navigate = useNavigate();

  const connectSubmit = () => {
    if(email1.length === 0){
      alert("Veuillez insérer votre email dans le champs E-mail");
    }
    else if(password1.length === 0){
      alert("Veuillez insérer votre mot de passe dans le champs Mot de passe");
    }
    else{
      const url = "https://s-p5.com/tiene/airqualityProject/traitement_connexion.php";
  
      let cData = new FormData();
      cData.append('email', email1);
      cData.append('password', password1);
  
      axios.post(url, cData)
      .then(response => {
        setMessage1(response.data);
  
        if (response.data.includes("Connexion établie")) {
          const userDataArray = response.data.split('\n');
          const userData = {};
  
          userDataArray.forEach(item => {
            if (item.includes("Nom d'utilisateur:")) {
              userData.username = item.split(": ")[1];
            } else if (item.includes("Adresse e-mail:")) {
              userData.email = item.split(": ")[1];
            }
          });
  
          // Vérifier si les informations de l'utilisateur ont été récupérées
          if (userData.username && userData.email) {
            const userDataJSON = JSON.stringify(userData); // Convertir en JSON
            Cookies.set('user_info', userDataJSON, { expires: 1 });
          }
  
          navigate('/dashboard');
        }
        else {
          setEmail1('');
          setPassword1('');
        }
      })
      .catch(error => setMessage1(error));
    }
  }

  let message1Style;

  if (message1 === "Inscription réussie.") {
    message1Style = "success-message1";
  } 
  else {
    message1Style = "error-message1";
  }

  return(
    <div className="Connexion_BlocA_container">
      <div className="logo1_container">
        <img src={logo1} className="logo1" alt="logo1"/>
      </div>

      <div className='login1_container'>
        <h2 className='login_title'>Se connecter à <br/> AIRQUALITY MONITOR</h2>

        <div className='email_container1'>
          <label className="email_label1">E-mail :</label>
          <input type="text" name="email1" className="email_field1" style={{ fontSize: '1em', paddingLeft: '15px' }} value={email1} onChange={(e) => setEmail1(e.target.value)} autoComplete="off"placeholder="Veuillez insérer votre email ici"/>
        </div>

        <div className='password_container1'>
          <label className="password_label1">Mot de passe :</label>
          <input type="password" name="password1" className="password_field1" value={password1} style={{ fontSize: '1em', paddingLeft: '15px' }} onChange={(e) => setPassword1(e.target.value)} autoComplete="off" placeholder="Veuillez insérer votre mot de passe ici"/>
        </div>

        <div className='message1_container'>
          <div className={`messages1 ${message1Style}`}>{message1}</div>
        </div>

        {/* <div className='forgotPassword_container'>
          <Link to="/forgotPassword" className='forgotButton_link'><div className='forgot_button'>Mot de passe oublié ?</div></Link>
        </div> */}

        <div className='login_button_container'>
          <input type="button" name="send1" className="login_button1" value="Se connecter" onClick={connectSubmit}/>
        </div>
      </div>
    </div>
  );
}

export default Connexion_BlocA;
