import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import axios from 'axios';
import PageInscription from './pages/PageInscription';
import PageConnexion from './pages/PageConnexion';
import Dashboard from './pages/Dashboard';
import PageHistoriques from './pages/PageHistoriques';
import PageMotDePasseOublie from './pages/PageMotDePasseOublie';



function App() {
  return (
    <Router>
      <div className = "App_container">
        <Routes>
          <Route exact path = "/" element= {<PageInscription />}/>
          <Route path = "/connexion" element= {<PageConnexion />}/>
          <Route path = "/dashboard" element= {<Dashboard />}/>
          <Route path = "/historics" element= {<PageHistoriques />}/>
          <Route path = "/forgotPassword" element= {<PageMotDePasseOublie />}/>
        </Routes>
      </div>
    </Router>
  )
}

export default App;
