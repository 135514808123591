import React, { useState, useEffect } from 'react';
import './Temp_card.css';
import temp_img from '../assets/temp_img.png';

function Temp_card({ data }) {
  // Utilisez les données passées en props
  const latestData = data.length > 0 ? data[0] : {}; // Prend la première donnée de la liste
  const [isBlinking, setIsBlinking] = useState(false);

  useEffect(() => {
    // Vérifiez si la valeur de température dépasse 25°C et activez le clignotement si nécessaire
    if (latestData.temperature && latestData.temperature > 38) {
      setIsBlinking(true);
    } else {
      setIsBlinking(false);
    }
  }, [latestData.temperature]);

  return (
    <div className="Temp_card_container">
      <div className='temp_img_container'>
        <img src={temp_img} alt="temp_img" className='temp_img' />
      </div>
      <h3 className='temp_title1'>Température</h3>
      <div className='temp_value'>{latestData.temperature || '0'}</div> {/* Utilisez la dernière valeur de température */}
      <div className='temp'>°C</div>
      <div className={`voyant3 ${isBlinking ? 'blink' : ''}`}></div>
    </div>
  );
}

export default Temp_card;
