import React from 'react';
import { AreaChart, Area, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import './CO2_chart.css';

// Fonction pour convertir "YYYY-MM-DD HH:mm:ss" en objet Date valide
const parseDate = (dateString) => {
  const [datePart, timePart] = dateString.split(' ');
  const [year, month, day] = datePart.split('-');
  const [hour, minute, second] = timePart.split(':');
  return new Date(year, month - 1, day, hour, minute, second);
};

// Composant personnalisé pour l'infobulle
const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    const date = parseDate(payload[0].payload.date_reception);

    // Si la date est invalide, retournez null pour éviter l'affichage incorrect
    if (isNaN(date.getTime())) {
      return null;
    }

    const dateString = date.toLocaleDateString(); // Format de la date
    const timeString = date.toLocaleTimeString(); // Format de l'heure

    return (
      <div className="custom-tooltip">
        <p>{`Date : ${dateString}`}</p>
        <p>{`Heure : ${timeString}`}</p>
        <p>{`CO2 : ${payload[0].value}`}</p>
      </div>
    );
  }

  return null;
};

function CO2_chart({ data }) {
  // Filtrer les données pour n'inclure que les niveaux de CO2 et ajouter une date simplifiée
  const filteredData = data.map(item => ({
    date_reception: item.date_reception, // Date complète pour le tooltip
    co2: item.co2,
  }));

  // Inverser l'ordre des données
  filteredData.reverse();

  return (
    <div className="CO2_chart_container">
      <h2 className='co2_chart_title'>Niveaux de CO2</h2>
      <ResponsiveContainer width="96%" height={180} className="co2_area">
        <AreaChart data={filteredData}>
          {/* <CartesianGrid strokeDasharray="3 3" /> */}
          <XAxis dataKey="date_reception" tick={false} hide={true} />
          <YAxis domain={[250, 3000]} />
          <Tooltip content={<CustomTooltip />} />
          <Area type="monotone" dataKey="co2" stroke="#00FF47" fill="#00FF47" />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
}


export default CO2_chart;
